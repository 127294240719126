
import { defineComponent, reactive, ref, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import useInvocationDetail from "@/core/services/compositions/invocation/useInvocationDetail";
import { translate } from "@/core/helpers/translate";
import moment from 'moment';
import { numberFormat } from "@/core/helpers/currency-helper";
import {
  showErrorPopup,
  showSuccessPopup,
  inputNumbersOnly
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import useInvocationStatus from "@/core/services/compositions/enum/useInvocationStatus";
import useBillStatus from "@/core/services/compositions/enum/useBillStatus";

interface Edit {
  status: string;
  transaction_notes: string;
  set_component: boolean;
  bill_name: string;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  customer_address: string;
  description: string;
  valid_until: string;
  bill_status: string;
  invoice_number: string;
  total_amount: number;
  billed_amount: number;
  paid_amount: number;
  admin_fee: number;
  amount: number;
  payment_ref_id: string;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const { invocationStatus } = useInvocationStatus();
    const { billStatus } = useBillStatus();
    const dialogVisible = ref(false)

    // breadcrumb
    setNewPageBreadcrumbs(t("invocation.breadcrumb"), [
      { title: t("invocation.edit") }
    ]);

    const edit = reactive<Edit>({
      status: '',
      transaction_notes: '',
      set_component: false,
      bill_name: '',
      customer_name: '',
      customer_email: '',
      customer_phone: '',
      customer_address: '',
      description: '',
      valid_until: '',
      bill_status: '',
      invoice_number: '',
      total_amount: 0,
      billed_amount: 0,
      paid_amount: 0,
      admin_fee: 0,
      amount: 0,
      payment_ref_id: '',
    });

    const invocationId = route.params.id;

    const { data, isValidating, mutate: refreshList } = useInvocationDetail(invocationId);

    watch(data, (data) => {
      if (data?.data.status == 'success') {
        edit.transaction_notes = data.data.transaction_notes;
        edit.invoice_number = data.data.invocation_bill.invoice_number;
        edit.bill_name = data.data.invocation_bill.name;
        edit.customer_name = data.data.invocation_bill.customer_name;
        edit.customer_email = data.data.invocation_bill.customer_email;
        edit.customer_phone = data.data.invocation_bill.customer_phone;
        edit.customer_address = data.data.invocation_bill.customer_address;
        edit.description = data.data.invocation_bill.description;
        edit.valid_until = data.data.invocation_bill.valid_until;
        edit.bill_status = data.data.invocation_bill.status;
        edit.total_amount = data.data.invocation_bill.total_amount;
        edit.billed_amount = data.data.invocation_bill.billed_amount;
        edit.paid_amount = data.data.invocation_bill.paid_amount;
        edit.admin_fee = parseInt(data.data.invocation_bill.admin_fee);
      }

      edit.payment_ref_id = data?.data.ref_id;
      edit.amount = data?.data.amount;

    });

    const submitUpdate = () => {
      dialogVisible.value = false;

      if (edit.transaction_notes == null || edit.transaction_notes == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Transaction Note] cannot empty',
          message: '[Transaction Note] cannot empty',
        })

        return;
      }

      if (edit.status == null || edit.status == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Status] cannot empty',
          message: '[Status] cannot empty',
        })

        return;
      }

      if (edit.status != 'failed' && edit.set_component == true) {
        if (edit.invoice_number == null || edit.invoice_number == '') {
          showErrorPopup({
            title: 'Error',
            text: '[Invoice Number] cannot empty',
            message: '[Invoice Number] cannot empty',
          })

          return;
        }

        if (edit.valid_until == null || edit.valid_until == '') {
          showErrorPopup({
            title: 'Error',
            text: '[Valid Until] cannot empty',
            message: '[Valid Until] cannot empty',
          })

          return;
        }

        if (edit.total_amount == null) {
          showErrorPopup({
            title: 'Error',
            text: '[Bill Total Amount] cannot empty',
            message: '[Bill Total Amount] cannot empty',
          })

          return;
        }

        if (edit.total_amount == null) {
          showErrorPopup({
            title: 'Error',
            text: '[Paid Amount] cannot empty',
            message: '[Paid Amount] cannot empty',
          })

          return;
        }
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        status: edit.status,
        transaction_notes: edit.transaction_notes,
      };

      if (edit.set_component) {
        if (data.value?.data.payments.length < 1) {
          Object.assign(payload, {
            bills: [{
              billable_id: data.value?.data.institution.code,
              name: edit.bill_name,
              customer_name: edit.customer_name,
              customer_email: edit.customer_email,
              customer_phone: edit.customer_phone,
              customer_address: edit.customer_address,
              description: edit.description,
              bill_status: edit.bill_status,
              invoice_number: edit.invoice_number,
              total_amount: edit.total_amount,
              billed_amount: edit.billed_amount,
              paid_amount: edit.paid_amount,
              admin_fee: edit.admin_fee,
              valid_until: edit.valid_until,
              amount: edit.amount,
            }]
          });
        }

        Object.assign(payload, {
          payments: [{
            amount: edit.amount,
            payment_ref_id: edit.payment_ref_id
          }]
        });
      }

      ApiService.setHeader();
      ApiService.put(
        `app/invocation/${invocationId}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const showDialog = () => {
      dialogVisible.value = true;
    }

    return {
      moment,
      numberFormat,
      invocationId,
      data,
      translate,
      edit,
      submitUpdate,
      submitButton,
      invocationStatus,
      billStatus,
      inputNumbersOnly,
      dialogVisible,
      showDialog
    };
  },
  methods: {}
});
